import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Button, Modal, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import burialSocietyFlyer from '../../assets/images/burial-society-flyer.png';
import member1 from '../../assets/images/member_1.jpg';
import member11 from '../../assets/images/member_11.jpg';
import member2 from '../../assets/images/member_2.jpg';
import member3 from '../../assets/images/member_3.jpg';
import member6 from '../../assets/images/member_6.jpg';
import member8 from '../../assets/images/member_8.jpg';
import Header from '../../components/Header';
import Layout from '../../components/layout';
import MemberTile from '../../components/MemberTile';

const AboutJikelele = ({ className }) => {
  return (
    <Layout title="About Jikelele" className={className} >
      <Header title="About Amangwe Jikelele Primary Co-operative Limited" />
      <div id="main">
        <section id="content" className="main">
          <header className="major">
            <h2>How it works</h2>
          </header>
          <p>
            Under construction...
          </p>
        </section>
      </div>
    </Layout >
  )
}

export default styled(AboutJikelele)`
  & header {
    text-align: center;
    &.major h2:after {
      margin: auto;
    }
    @media screen and (max-width: 736px) {
      &.major {
        margin-bottom: 2em;
      }
    }
  }

  & .members {
    margin-top: 10px;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
